header {
  padding: 12px;
  width: 100%;
  position: fixed;
  z-index: 1000;
  border-bottom: 1px solid #ddd;
}
header .logo {
  display: inline-block;
}
header .logo img {
  max-width: 220px;
}
@media (max-width: 767px) {
  header .logo .logo-img {
    width: 160px;
  }
}
header .logo .small-logo-img {
  display: none;
  width: 32px;
  margin-right: 8px;
  margin-left: 0px;
}
@media (max-width: 767px) {
  header .ant-input-search {
    display: none;
  }
}
header.collapsed .logo .logo-img {
  display: none;
}
header.collapsed .logo .small-logo-img {
  display: inline;
}
@media (max-width: 767px) {
  header.collapsed .ant-input-search {
    display: initial;
  }
}
.app-header-inner {
  display: flex;
  align-items: center;
}
.app-header__search.ant-input-search {
  width: 80px;
}
@media (min-width: 768px) {
  .app-header__search.ant-input-search {
    width: 200px;
  }
}
.app-header--right {
  margin-left: auto;
}
.app-header--list *:not(:last-child) {
  margin-right: 8px;
}
.objective-icone-img {
  height: 24px;
  width: 24px;
  cursor: pointer;
}
