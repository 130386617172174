.sidebar-widget {
  margin-bottom: 15px;
}
.lessons-list {
  display: flex;
}
.lessons-list .lessons {
  width: 20%;
}
.lessons-list .calculate {
  width: 80%;
}
