.action-icones {
  text-align: center;
}
.action-icones .edit-icone {
  margin-right: 10px;
  cursor: pointer;
  font-size: 18px;
}
.action-icones .delete-icone {
  margin-left: 10px;
  cursor: pointer;
  font-size: 18px;
}
