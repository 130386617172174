.login-from {
  background: #fff;
  padding: 10px;
  border-radius: 20px;
}
@media screen and (min-width: 768px) {
  .login-from {
    min-width: 800px;
    padding: 20px;
  }
}
.signup-from {
  background: #fff;
  padding: 10px 10px;
  border-radius: 20px;
}
@media screen and (min-width: 768px) {
  .signup-from {
    min-width: 800px;
    padding: 20px 70px;
  }
}
@media screen and (max-width: 768px) {
  .signup-from .btn-cta {
    padding: 0.6em 0.5em !important;
  }
}
.forgot-from {
  background: #fff;
  padding: 10px 10px;
  border-radius: 20px;
}
@media screen and (min-width: 768px) {
  .forgot-from {
    min-width: 800px;
    padding: 20px 70px;
  }
}
.steps-content {
  margin-top: 20px;
}
.ant-form-item {
  margin-bottom: 10px !important;
}
@media screen and (max-width: 768px) {
  .login-from .btn-cta {
    padding: 0.6em 0.5em !important;
  }
}
.login-hading {
  font-weight: 600;
  font-size: 30px;
  text-align: center;
}
@media screen and (max-width: 768px) {
  .col-secound {
    display: flex;
  }
}
.divide-line {
  border: 1px solid #ddd9d9;
  height: 142px;
  width: 0px;
}
@media screen and (max-width: 768px) {
  .divide-line {
    width: 50% !important;
    height: 1px !important;
    line-height: 1px !important;
  }
}
.divide-or {
  margin: 15px 0px;
  margin-left: -8px;
}
@media screen and (max-width: 768px) {
  .divide-or {
    margin: 0px 15px !important;
    bottom: -39px !important;
    margin-top: -7px !important;
  }
}
.first-from {
  padding: 8px 20px !important;
}
.additional-info {
  line-height: 1.8rem !important;
}
.hide-btn {
  position: absolute;
  right: 24px;
  top: 164px;
}
.login-btn {
  text-transform: none !important;
  font-size: 16px !important;
  line-height: 1.5 !important;
  color: #fff !important;
  font-weight: 600 !important;
}
.google-btn {
  text-transform: none !important;
  font-size: 16px !important;
  line-height: 1.5 !important;
}
.img-google {
  height: 19px;
  margin-right: 10px;
}
.img-facebook {
  height: 22px;
  margin-right: 10px;
}
.img-email {
  height: 26px;
  margin-right: 10px;
}
.user-icone {
  text-align: center;
  margin-top: 15px;
  margin-bottom: 10px;
}
.img-user-icone {
  height: 50px;
}
