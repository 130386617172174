.sidebar {
  background: #fff;
  background-repeat: no-repeat;
  border-right: 1px solid #ddd;
  padding: 0;
  padding-top: 62px;
  padding-left: 8px;
}
.sub-text {
  color: #333;
  padding: 0 15px;
}
.ant-menu {
  background-color: transparent;
  color: #333;
  border-right: 0;
}
.ant-menu .ant-menu-item {
  padding-left: 8px;
}
.ant-menu-inline .ant-menu-submenu-arrow {
  top: 20px;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background: transparent !important;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected:after {
  border-right: none !important;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected a {
  text-decoration: none;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected .normal-menu .ant-menu-submenu-arrow {
  top: 50% !important;
}
.collapsed-sidebar {
  padding-left: 0;
}
.collapsed-sidebar .ant-menu-item {
  padding-left: 2px;
  padding-right: 0;
  text-align: center;
}
.collapsed-sidebar .ant-menu-item .nav-text {
  display: none;
}
.collapsed-sidebar .ant-menu-item i {
  font-size: 20px;
}
.collapsed-sidebar .progress-menu {
  display: none;
}
.progress-menu {
  background: #fff;
  color: #333;
}
.progress-menu li,
.progress-menu .ant-progress-text span {
  color: #333 !important;
}
.progress-menu .ant-menu-sub.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  line-height: 16px;
}
.progress-menu .ant-menu-sub.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title a {
  color: #333;
}
.progress-menu > .ant-menu-submenu-title .ant-menu-submenu-arrow {
  top: 50%;
}
.progress-menu .item-degree-active a {
  text-decoration: underline;
  color: #faad14 !important;
}
.progress-menu .item-course-active a {
  text-decoration: underline;
  color: #52c41a !important;
}
.progress-menu .item-lesson-active a {
  text-decoration: underline;
  color: #13c2c2 !important;
}
.progress-menu .item-objective-active {
  text-decoration: underline;
  color: #46a5e5 !important;
}
.course-progress-menu a,
.course-progress-menu a:hover {
  color: #333;
}
.main-content {
  margin: 64px auto 0;
  padding: 1em;
}
