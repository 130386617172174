.degree-card {
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 15px;
}
.degree-card img {
  width: 100%;
  max-width: 100%;
}
.degree-card .degree-card__body {
  border-radius: 0 0 10px 10px;
  padding: 1.625rem;
}
.degree-card .degree-card__date {
  opacity: 0.7;
}
.degree-card .degree-card__title {
  line-height: 1.625rem;
  font-size: 1em;
  margin: 5px 0;
  font-weight: 400;
  color: #333;
}
.degree-card-list {
  border: rgba(0, 0, 0, 0.2);
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  margin: auto;
  margin-bottom: 15px;
}
.degree-card-list img {
  width: 100%;
  max-width: 100%;
}
.degree-card-list .degree-card__img {
  padding: 0;
}
.degree-card-list .degree-card__body {
  display: flex;
  flex-direction: column;
  padding: 0.8rem;
  text-align: left;
}
.degree-card-list .degree-card__tag {
  font-size: 14px;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-weight: 400;
  opacity: 0.8;
}
.degree-card-list .degree-card__title {
  margin: 0 0 0.625rem;
}
.degree-card-list .degree-card__content {
  padding: 0 0 0.8rem;
  flex: 1;
}
.degree-card-list .degree-card__author {
  padding-top: 0.8rem;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  font-size: 14px;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-weight: 400;
}
.btn-start {
  text-align: end;
}
