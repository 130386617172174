.lessons {
  display: flex;
}
.lessons .lessons-tittie {
  width: 65%;
}
.lessons .calculation {
  display: flex;
  width: 35%;
  padding-left: 5px;
}
.lessons .calculation .tittie {
  width: 25%;
  font-size: 14px;
}
.objectives-list {
  display: flex;
}
.objectives-list .objectives-tittie {
  width: 80%;
}
.objectives-list .calculate {
  display: flex;
  width: 20%;
  font-weight: 400;
  text-align: center;
}
.objectives-list .calculate .tittie {
  width: 50%;
}
