.notes {
  position: fixed;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  z-index: 9999;
  transition: right 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
}
.notes--btn {
  background-color: #fff;
  border-radius: 0;
  border-color: transparent;
}
.notes--btn:hover,
.notes--btn:active,
.notes--btn:focus {
  background-color: #1b5b6b;
}
.notes--btn:hover .anticon.notes--icon,
.notes--btn:active .anticon.notes--icon,
.notes--btn:focus .anticon.notes--icon {
  color: #fff;
}
.notes--icon.anticon {
  color: #1b5b6b;
  font-size: large;
}
.notes--form {
  text-align: right;
  margin-bottom: 1em;
}
.notes--form-btn {
  color: #333;
}
.notes--form label {
  visibility: hidden;
  height: 0.1px;
  width: 0.1px;
  position: absolute;
  left: 50000px;
}
.notes--note {
  border-bottom: 1px solid #e8e8e8;
  padding-bottom: 16px;
  margin-bottom: 16px;
}
.notes--note-header {
  color: #666;
  font-size: 0.7rem;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.notes--note-header:active,
.notes--note-header:hover,
.notes--note-header:focus {
  color: #666;
}
.notes--note-header > span {
  margin: auto;
}
.notes--note-header > span + .anticon {
  margin-left: auto;
}
.notes--note-body {
  font-size: 0.85rem;
  color: #333;
  padding: 4px 6px;
  width: 100%;
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.notes--note-body--collapsed {
  padding-right: 20px;
}
.notes--note-body--collapsed > span {
  max-height: 28px;
  line-height: 28px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: flex;
  align-items: center;
  position: relative;
  display: block;
}
.notes--note .ant-form-item-control {
  text-align: right;
}
.notes--note .ant-form-item-children {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.notes--note .mr-2 {
  margin-right: 1em;
}
.notes--note .w-100 {
  width: 100%;
}
.notes--note label {
  visibility: hidden;
  height: 0.1px;
  width: 0.1px;
  position: absolute;
  left: 50000px;
}
.notes--note-text {
  max-width: calc(100% - 70px);
}
.notes--notes-box ~ .notes--notes-box {
  margin-top: 2em;
}
.notes--notes-subheading {
  font-size: 0.9rem;
  color: #333;
  border-bottom: 1px solid #e8e8e8;
  padding: 0.4em 0;
  margin: 0.5em 0;
}
.notes--drawer .ant-drawer-content-wrapper {
  background-color: #f9f9f9;
}
.notes--drawer .drawer--title {
  font-size: 1.25rem;
  font-weight: normal;
  margin: 0 0 0.25em;
}
.notes--drawer .drawer--subtitle {
  font-size: 0.9rem;
  margin: 0;
}
