.sssss {
  height: 16px;
  width: 16px;
  border-radius: 50%;
  border: 2px solid #46a5e5;
  display: inline-block;
  margin-right: 7px;
}
.enrolledDegrees {
  min-width: 250px;
}
.tab-progress .data-row {
  display: flex;
}
.tab-progress .data-row .title {
  width: 30%;
}
.tab-progress .data-row .description {
  width: 70%;
}
.tab-progress .data-row .description .select-option {
  min-width: 100px;
}
.select-filter {
  min-width: 300px;
}
.selected-month-button {
  text-align: end;
  position: relative;
  top: 36px;
  z-index: 1;
  right: 50px;
}
.selected-month-button .inactive-month {
  margin: 10px 10px;
  color: #424242;
}
.selected-month-button .active-month {
  margin: 10px 10px;
  color: #46a5e5;
}
