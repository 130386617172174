.schedule-day-agenda-avatar {
  text-align: center;
  width: 80px;
}
.schedule-day-agenda-avatar .schedule-day-agenda-hours {
  font-size: 18px;
  font-weight: 800;
}
.schedule-day-agenda-avatar .schedule-day-agenda-confirmed {
  font-size: 16px;
}
